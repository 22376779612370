import React, { useState, useRef, useMemo, useEffect } from "react";
import "./Chart.css";
import { takaFormatter } from "../../../../../../../../Utilities/HelperFunctions/takaFormatter";
import { useAxiosInstance } from "../../../../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_Investors_API } from "../../../../../../../../Utilities/APIs/APIs";
import { useParams } from "react-router-dom";
import LegendContainer from "./LegendContainer/LegendContainer";
import InvestmentsSummary from "./InvestmentsSummary";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`;
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "2-digit" });
  return `${day}-${month}-${year}`;
};

const calculateMaturityDate = (dateStr, tenureInDays) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + tenureInDays);
  return formatDate(date.toISOString().split("T")[0]);
};

const brightenColor = (color, amount) => {
  let usePound = false;

  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);
  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (
    (usePound ? "#" : "") +
    (g | (b << 8) | (r << 16)).toString(16).padStart(6, "0")
  );
};

const maturityEquation = (amount, rate, tenure) => {
  return amount * Math.pow(1 + rate / 100, tenure / 365);
};

const useSyncScroll = (initialRef, maturedRef) => {
  useEffect(() => {
    const handleScroll = (event) => {
      const otherRef =
        event.target === initialRef.current
          ? maturedRef.current
          : initialRef.current;

      if (otherRef) {
        otherRef.scrollLeft = event.target.scrollLeft;
      }
    };

    if (initialRef.current && maturedRef.current) {
      initialRef.current.addEventListener("scroll", handleScroll);
      maturedRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (initialRef.current && maturedRef.current) {
        initialRef.current.removeEventListener("scroll", handleScroll);
        maturedRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [initialRef, maturedRef]);
};

const useMaturityCalculations = (activeInvestments) => {
  const maxMaturedAmount = useMemo(() => {
    return Math.max(
      ...activeInvestments.map((investment) =>
        maturityEquation(investment.amount, investment.rate, investment.tenure)
      )
    );
  }, [activeInvestments]);

  const {
    totalInvestment,
    totalMaturity,
    totalProjectedProfit,
    netProfit,
    totalInvestmentAndProjectedProfit,
  } = useMemo(() => {
    const totalInvestment = activeInvestments.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );

    const totalInvestmentAndProjectedProfit = activeInvestments.reduce(
      (acc, curr) =>
        acc + curr.amount * (curr.rate / 100) * (curr.tenure / 365),
      totalInvestment
    );

    const totalMaturity = activeInvestments.reduce((acc, curr) => {
      return acc + maturityEquation(curr.amount, curr.rate, curr.tenure);
    }, 0);

    const totalProjectedProfit = totalMaturity - totalInvestment;
    const netProfit = totalMaturity - totalInvestment;

    return {
      totalInvestment,
      totalMaturity,
      totalProjectedProfit,
      netProfit,
      totalInvestmentAndProjectedProfit,
    };
  }, [activeInvestments]);

  return {
    maxMaturedAmount,
    totalInvestment,
    totalMaturity,
    totalProjectedProfit,
    netProfit,
    totalInvestmentAndProjectedProfit,
  };
};

const useHover = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };
  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  return { hoveredId, handleMouseEnter, handleMouseLeave };
};

function Chart() {
  const [activeInvestments, setActiveInvestments] = useState([]);

  const { id } = useParams();
  const initialRef = useRef(null);
  const maturedRef = useRef(null);
  useSyncScroll(initialRef, maturedRef);

  const {
    maxMaturedAmount,
    netProfit,
    totalInvestment,
    totalMaturity,
    totalInvestmentAndProjectedProfit,
  } = useMaturityCalculations(activeInvestments);
  const { hoveredId, handleMouseEnter, handleMouseLeave } = useHover();

  console.log(totalInvestmentAndProjectedProfit);

  const sortedInvestments = useMemo(
    () =>
      [...activeInvestments].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      ),
    [activeInvestments]
  );

  const sortedMaturities = useMemo(
    () =>
      [...activeInvestments].sort(
        (a, b) =>
          new Date(calculateMaturityDate(a.date, a.tenure)) -
          new Date(calculateMaturityDate(b.date, b.tenure))
      ),
    [activeInvestments]
  );

  const axiosInstance = useAxiosInstance();

  const colors = [
    "#F6ABA9",
    "#DFC0BC",
    "#FEE29F",
    "#B9E5EF",
    "#9EEBEB",
    "#ECBC91",
    "#FDD8B2",
    "#D7C5DF",
    "#E6DAE9",
    "#E3EDBA",
    "#D6DBC5",
  ];

  const [activeDisbursedInvestments, setActiveDisbursedInvestments] = useState(
    []
  );

  console.log(activeDisbursedInvestments);

  useEffect(() => {
    async function fetchAndSetInvestments() {
      try {
        const { data } = await axiosInstance.get(
          `${MANAGE_Investors_API}getAllInvestmentOfSingleInvestor/${id}`
        );

        // Filter investments to include only active and undisbursed ones
        const undisbursedInvestments = data.filter(
          (investment) => investment.isActive && !investment.disbursed
        );

        // Assign colors to the filtered undisbursed investments
        const investmentsWithColors = undisbursedInvestments.map(
          (investment, index) => ({
            ...investment,
            color: colors[index % colors.length], // Assign a color to each investment
          })
        );

        setActiveInvestments(
          Array.isArray(investmentsWithColors) ? investmentsWithColors : []
        );

        // Filter investments to include only active and disbursed ones
        const disbursedInvestments = data.filter(
          (investment) => investment.isActive && investment.disbursed
        );

        setActiveDisbursedInvestments(
          Array.isArray(disbursedInvestments) ? disbursedInvestments : []
        );
      } catch (error) {
        console.error("Failed to fetch investments", error);
      }
    }

    fetchAndSetInvestments();
  }, [axiosInstance]); // Add id to the dependency array if it's used inside the effect

  return (
    <div className="graphWrapper  flex flex-col justify-start h-dvh  2xl:py-20">
      <InvestmentsSummary
        investorId={id}
        activeInvestments={activeInvestments}
        activeDisbursedInvestments={activeDisbursedInvestments}
      />
      <section className="section relative">
        <div className="legend-container"></div>
        <div className="chart-container grid grid-flow-col grid-rows-1 !w-full justify-items-center">
          <div className="chart-scale">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="scale-mark">
                <span>
                  {takaFormatter(
                    Math.round(
                      ((maxMaturedAmount / 5) * (5 - i) + 0.25) / 100000
                    )
                  )}
                  L
                </span>
              </div>
            ))}
          </div>

          <div className="dateWrapper">
            {sortedInvestments.map((investment, index) => (
              <div key={index} className="dateMilestone">
                <div className="dateMilestone-line"></div>
                <div className="dateMilestone-text">
                  <p>{formatDate(investment.date)}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="dateWrapper_matured">
            {sortedMaturities.map((investment, index) => (
              <div key={index} className="dateMilestone">
                <div className="dateMilestone-line"></div>
                <div className="dateMilestone-text">
                  <p>
                    {calculateMaturityDate(investment.date, investment.tenure)}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="chart-section h-[40dvh]" ref={initialRef}>
            <p className="absolute -top-20 text-3xl uppercase text-gray-800">
              Investments
            </p>

            <p className="absolute -top-10 text-xl uppercase text-gray-800 ">
              Total : {takaFormatter(totalInvestment)}
            </p>

            {sortedInvestments.map((investment, index) => (
              <div
                key={index}
                className={`investment-bar ${
                  hoveredId === investment._id ? "hover" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(investment._id)}
                onMouseLeave={handleMouseLeave}
                style={{
                  opacity: hoveredId && hoveredId !== investment._id ? 0.25 : 1,
                }}
              >
                <div
                  className="bar barAnim "
                  style={{
                    height: `${(investment.amount / maxMaturedAmount) * 40}dvh`,
                    backgroundColor: investment.color,
                  }}
                >
                  <div className="bar-info rotate-[-90deg] overflow-visible">
                    <p className="w-max">
                      {investment?.company?.name.slice(0, 15)}... -
                      {takaFormatter(investment?.amount)}
                    </p>
                  </div>
                </div>

                {hoveredId === investment._id && (
                  <div className="tooltip">
                    <div className="tooltip-line"></div>
                    <div className="tooltip-text">
                      <div>
                        <p>Investment:</p>
                        <p>{takaFormatter(investment.amount)}</p>
                      </div>
                      <div>
                        <p>Tenure:</p>
                        <p>{investment.tenure} days</p>
                      </div>
                      <div>
                        <p>ROI:</p>
                        <p>{investment.rate}%</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="separator">
            <div className="separator-line"></div>
          </div>

          <div className="chart-section h-[40dvh]" ref={maturedRef}>
            <p className="absolute -top-20 text-3xl uppercase text-gray-800">
              Investment + Projected Profit
            </p>
            <p className="absolute -top-10 text-xl uppercase text-gray-800 ">
              Total : {takaFormatter(totalInvestmentAndProjectedProfit)}
            </p>

            {sortedMaturities.map((investment, index) => {
              const initialAmount = investment.amount;
              const maturedAmount = maturityEquation(
                investment.amount,
                investment.rate,
                investment.tenure
              );
              const totalHeight = (maturedAmount / maxMaturedAmount) * 40;
              const initialHeight = (initialAmount / maxMaturedAmount) * 40;

              const initialColor = investment.color;
              const growthColor = brightenColor(initialColor, -20);

              return (
                <div
                  key={investment._id}
                  className={`investment-bar ${
                    hoveredId === investment._id ? "hover" : ""
                  }`}
                  onMouseEnter={() => handleMouseEnter(investment._id)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    opacity:
                      hoveredId && hoveredId !== investment._id ? 0.25 : 1,
                  }}
                >
                  <div
                    className="bar barAnim"
                    style={{
                      height: `${totalHeight}dvh`,
                      background: `linear-gradient(to top, ${initialColor} ${initialHeight}dvh, transparent ${initialHeight}px), repeating-linear-gradient(145deg, ${growthColor}, ${growthColor} 4px, #ededed 1px, #efefef 8px)`,
                    }}
                  >
                    <div className="bar-info rotate-[-90deg] m-auto h-fit overflow-clip">
                      <p className="w-max">
                        {investment?.company?.name.slice(0, 15)}... -
                        {takaFormatter(
                          investment.amount +
                            investment.amount *
                              (investment.rate / 100) *
                              (investment.tenure / 365)
                        )}
                      </p>
                    </div>
                  </div>

                  {hoveredId === investment._id && (
                    <div className="tooltip">
                      <div className="tooltip-line"></div>

                      <div className="tooltip-text">
                        <div>
                          <p>Investment:</p>
                          <p>{takaFormatter(investment.amount)}</p>
                        </div>
                        <div>
                          <p>Profit:</p>
                          <p>
                            {takaFormatter(
                              investment.amount *
                                (investment.rate / 100) *
                                (investment.tenure / 365)
                            )}
                          </p>
                        </div>
                        <div>
                          <p>Total:</p>
                          <p>
                            {takaFormatter(
                              investment.amount +
                                investment.amount *
                                  (investment.rate / 100) *
                                  (investment.tenure / 365)
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="py-10">
          <LegendContainer />
        </div>
      </section>
    </div>
  );
}
 
export default Chart;
