import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../Partials/Forms/Form";
import EmailInput from "../../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";
import ShortTextInput from "../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ImageInput from "../../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import SwitchInput from "../../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";

function UpdateInvestor({
  employee,
  api,
  setShowUpdateForm,
  triggerFetch,
  targetID,
}) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [startingProfit, setStartingProfit] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchInvestorData() {
      try {
        setLoading(true);
        const response = await axiosInstance.get(api + "getSingleInvestor/" + targetID);
        if (response && response.data) {
          setEmail(response.data.email);
          setName(response.data.name);
          setIsActive(response.data.isActive);
          setStartingProfit(response.data.startingProfit);
          setCompany(response.data.company);
          setDesignation(response.data.designation);
          setImage(response.data.image);
        }
      } catch (error) {
        setFormError("Error fetching investor data. Please try again.");
      } finally {
        setLoading(false);
      }
    }
    fetchInvestorData();
  }, [api, targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setFormError(null);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", name);
    formData.append("isActive", isActive);
    formData.append("startingProfit", startingProfit);
    formData.append("company", company);
    formData.append("designation", designation);
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await axiosInstance.put(api + "updateSingleInvestor/" + targetID, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        setShowUpdateForm(false);
        triggerFetch();
      }
    } catch (error) {
      setFormError("Error updating the investor. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formError && <div className="text-red-500 mb-4">{formError}</div>}

      <ShortTextInput
        label={"Name"}
        value={name}
        placeholder={"Please Enter a Name"}
        setState={setName}
        disabled={loading}
      />
      <EmailInput
        label={"Email"}
        value={email}
        placeholder={"Please Enter an Email"}
        setState={setEmail}
        disabled={loading}
      />
      <ShortTextInput
        label={"Company"}
        value={company}
        placeholder={"Please Enter Company Name"}
        setState={setCompany}
        disabled={loading}
      />
      <ShortTextInput
        label={"Designation"}
        value={designation}
        placeholder={"Please Enter Designation"}
        setState={setDesignation}
        disabled={loading}
      />
      <NumberInput
        label={"Starting Profit (Taka)"}
        value={startingProfit}
        placeholder={"Add Starting Profit if any"}
        setState={setStartingProfit}
        disabled={loading}
      />
      <SwitchInput
        label={"Is Active?"}
        value={isActive}
        checked={isActive}
        toggleSwitch={() => setIsActive(!isActive)}
        setState={setIsActive}
        disabled={loading}
      />
      <ImageInput
        allowUpdateImage
        label={"Profile Image"}
        state={image}
        setState={setImage}
        fieldId={"Investor Image"}
        disabled={loading}
      >
        Select Profile Picture
      </ImageInput>
      <FormSubmitButton text={loading ? "Updating..." : "Update Investor"} disabled={loading} />
    </Form>
  );
}

export default UpdateInvestor;
