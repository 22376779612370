import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../../Contexts/GlobalContexts/LayoutContext";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import Form from "../../../Partials/Forms/Form";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SwitchInput from "../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";

function UpdateCompany({ setShowUpdateForm, api, targetID }) {
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const { triggerFetch } = useContext(LayoutContext);
  const axiosInstance = useAxiosInstance();

  const { info: company } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + targetID,
  });

  useEffect(() => {
    if (company) {
      setName(company.name);
      setIsActive(company.isActive);
    }
  }, [company]);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true); // Start loading
    setError(""); // Clear previous errors

    const itemData = {
      name,
      isActive,
    };

    try {
      const response = await axiosInstance.patch(
        "employeeApp/protected/manageCompanies/company/" + targetID,
        itemData
      );
      if (response) {
        triggerFetch();
        setShowUpdateForm(false); // Close the form on success
      }
    } catch (error) {
      setError(
        "An error occurred while updating the company. Please try again."
      );
    } finally {
      setLoading(false); // Stop loading after the request completes
    }
  }

  return (
    <div className="update_form">
      {error && <div className="error_message text-red-500 mb-2">{error}</div>} {/* Error message */}
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label="Name"
          value={name}
          placeholder="Enter Name"
          setState={setName}
          disabled={loading} // Disable input during loading
        />

        <SwitchInput
          label="Is Active"
          toggleSwitch={() => setIsActive(!isActive)}
          checked={isActive}
          disabled={loading} // Disable input during loading
        />

        <FormSubmitButton text={loading ? "Updating..." : "Update Company"} disabled={loading} />
      </Form>
    </div>
  );
}

export default UpdateCompany;
