import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useState } from "react";
import Form from "../../../../Partials/Forms/Form";
import DateInput from "../../../../Partials/Forms/FormInputs/DateInput/DateInput";
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function CreateToken({
  api,
  targetID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  // Form state
  const [tokenName, setTokenName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [tokenDescription, setTokenDescription] = useState("");
  const [totalIssuedTokens, setTotalIssuedTokens] = useState(0);
  const [lockInPeriod, setLockInPeriod] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [finalInvestmentDate, setFinalInvestmentDate] = useState(new Date());
  const [initialTokenValue, setInitialTokenValue] = useState(0);
  const [roi, setRoi] = useState(0);

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("investor", targetID);
    formData.append("tokenName", tokenName);
    formData.append("thumbnail", thumbnail);
    formData.append("tokenDescription", tokenDescription);
    formData.append("totalIssuedTokens", totalIssuedTokens);
    formData.append("lockInPeriod", lockInPeriod);
    formData.append("tenure", tenure);
    formData.append("finalInvestmentDate", finalInvestmentDate);
    formData.append("initialTokenValue", initialTokenValue);
    formData.append("roi", roi);

    try {
      const response = await axiosInstance.post(api + "createToken", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.data) {
        // Reset form
        setTokenName("");
        setThumbnail(null);
        setTokenDescription("");
        setTotalIssuedTokens(0);
        setLockInPeriod(0);
        setTenure(0);
        setFinalInvestmentDate(new Date());
        setInitialTokenValue(0);
        setRoi(0);
        setShowEditSection(false);
        triggerFetch();
      }
    } catch (error) {
      console.error("Error creating token:", error);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label={"Token Name"}
        value={tokenName}
        placeholder={"Enter Token Name"}
        setState={setTokenName}
      />
      <ImageInput
        allowCreateImage
        state={thumbnail}
        setState={setThumbnail}
        fieldId={"tokenThumbnail"}
      >
        Token Thumbnail
      </ImageInput>

      {/* CKEditor for Token Description */}
      <div className="form-group">
        <label>Token Description</label>
        <CKEditor
          editor={ClassicEditor}
          data={tokenDescription}
          onChange={(event, editor) => {
            const data = editor.getData();
            setTokenDescription(data); // Update tokenDescription state
          }}
        />
      </div>

      <NumberInput
        label={"Total Issued Tokens"}
        value={totalIssuedTokens}
        placeholder={"Enter Total Issued Tokens"}
        setState={setTotalIssuedTokens}
      />
      <NumberInput
        label={"Lock-in Period (Days)"}
        value={lockInPeriod}
        placeholder={"Enter Lock-in Period in Days"}
        setState={setLockInPeriod}
      />
      <NumberInput
        label={"Tenure (Days)"}
        value={tenure}
        placeholder={"Enter Tenure in Days"}
        setState={setTenure}
      />
      <DateInput
        label={"Final Investment Date"}
        value={finalInvestmentDate}
        placeholder={"Enter Final Investment Date"}
        setState={setFinalInvestmentDate}
      />
      <NumberInput
        label={"Initial Token Value"}
        value={initialTokenValue}
        placeholder={"Enter Initial Token Value"}
        setState={setInitialTokenValue}
      />
      <NumberInput
        label={"Annualized ROI (%)"}
        value={roi}
        placeholder={"Enter ROI"}
        setState={setRoi}
      />
      <FormSubmitButton text="Create Token" />
    </Form>
  );
}
