import {
  RiAliensLine,
  RiCoinLine,
  RiEmotionLine,
  RiFlaskLine,
  RiGasStationLine,
  RiGroupLine,
  RiHeart3Line,
  RiHome2Line,
  RiHomeOfficeLine,
  RiMapLine,
  RiOrderPlayLine,
  RiStarLine,
  RiSwordLine,
} from "react-icons/ri";
import { MdContentCopy, MdOutlineAppSettingsAlt } from "react-icons/md";
import { SidebarItem } from "../Layout/SideAndNavBar/Sidebar/SidebarItem/SidebarItem";
import { GiToken } from "react-icons/gi";

function NavItems() {
  return (
    <>
      <SidebarItem title="Dashboard" children={<RiHome2Line />} />
      {/* <SidebarItem title='WebsiteContent' children={<MdContentCopy />} /> */}
      {/* <SidebarItem title='EcommercesSettings' children={<MdOutlineAppSettingsAlt />} /> */}
      <SidebarItem title="Working Capital" children={<RiHomeOfficeLine />} />
      <SidebarItem title="Nano Asset" children={<GiToken />} />
      <SidebarItem title="Employees" children={<RiGroupLine />} />
      {/* <SidebarItem title='Items' children={<MdOutlineAppSettingsAlt />} /> */}
      {/* <SidebarItem title='Orders' children={<RiOrderPlayLine />} /> */}
      {/* 
      {(bizDev || projectManagement) && <SidebarItem title='Biz Dev' children={<RiAliensLine />} />}
      {execution && <SidebarItem title='Execution' children={<RiSwordLine />} />}
      {relationship && <SidebarItem title='Relationship' children={<RiHeart3Line />} />}
      {acquisition && <SidebarItem title='Acquisition' children={<RiGasStationLine />} />}
      {rnd && <SidebarItem title='RnD' children={<RiFlaskLine />} />}
      {marketing && <SidebarItem title='Marketing' children={<RiStarLine />} />}
      {hr && <SidebarItem title='HR' children={<RiEmotionLine />} />}
      {finance && <SidebarItem title='Finance' children={<RiCoinLine />} />}
      {officeManagement && <SidebarItem title='Office Management' children={<RiHomeOfficeLine />} />} */}
    </>
  );
}

export default NavItems;
