import {
  MANAGE_ALLINVESTMENTS_API,
  MANAGE_Company_API,
  MANAGE_Employees_API,
  MANAGE_INVESTOR_USERS_API,
  MANAGE_INVESTOR_USERS_INVESTORS_API,
  MANAGE_Investors_API,
  MANAGE_TokenInvestors_API,
  MANAGE_Tokens_API,
} from "../../../Utilities/APIs/APIs";
import {
  CUSTOMERS_API,
  EMPLOYEES_API,
} from "../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import Companies from "./Company/Companies.js";
import Dashboard from "./Dashboard/Dashboard";
import EmployeeScreen from "./Users/EmployeeScreen/EmployeeScreen";
import Employees from "./Users/EmployeeScreen/Employees/Employees";
import InviteEmployees from "./Users/EmployeeScreen/InviteEmployees/InviteEmployees";
import InvestorScreen from "./Users/InvestorScreen/InvestorScreen.js";
import Investments from "./Users/InvestorScreen/Investors/Investments/Investments.js";
import Investors from "./Users/InvestorScreen/Investors/Investors.js";
import InviteInvestors from "./Users/InvestorScreen/InviteInvestors/InviteInvestors.js";
import Users from "./Users/Users";
import WebsiteContent from "./WebsiteContent/WebsiteContent";
import TokenInvestors from "./TokenScreen/TokenInvestorScreen/TokenInvestors/TokenInvestors.js";
import InviteTokenInvestors from "./TokenScreen/TokenInvestorScreen/InviteTokenInvestors/InviteTokenInvestors.js";
import TokenInvestorScreen from "./TokenScreen/TokenInvestorScreen/TokenInvestorScreen.js";
import TokenScreen from "./TokenScreen/TokenScreen.js";
import WCScreen from "./WCScreen/WCScreen.js";
import Tokens from "./TokenScreen/Tokens/Tokens.js";
import InvestmentDocuments from "./Users/InvestorScreen/Investors/Investments/Documents/InvestmentDocuments.js";
import TokenImages from "./TokenScreen/Tokens/TokenImages/TokenImages.js";
import AllInvestments from "./WCScreen/AllInvestments/AllInvestments.js";
import CompanyInvestments from "./Company/CompanyInvestments/CompanyInvestments.js";
import Chart from "./Users/InvestorScreen/Investors/Investments/Chart/Chart.jsx";
import InvestorUsers from "./WCScreen/InvestmentUser/InvestmentUser.js";
import InvestorUsersInvestors from "./WCScreen/InvestmentUser/InvestorUsersInvestors/InvestorUsersInvestors.js";
import SingleInvestorUsersInvestors from "./WCScreen/InvestmentUser/InvestorUsersInvestors/SingleInvestorUsersInvestors.js";
import SingleInvestorsInvestorUsers from "./Users/InvestorScreen/Investors/SingleInvestorsInvestmentUsers/SingleInvestorsInvestmentUsers.js";

const MainRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "websiteContent",
    element: <WebsiteContent />,
  },

  {
    path: "Employees",
    // element: <Users />,
    element: <EmployeeScreen />,
  },
  {
    path: "Companies",
    element: <Companies api={"api/" + MANAGE_Company_API} />,
  },
  {
    path: "NanoAsset",
    element: <TokenScreen api={"api/" + MANAGE_TokenInvestors_API} />,
  },
  {
    path: "NanoAssets",
    element: <Tokens api={"api/" + MANAGE_Tokens_API} />,
  },
  {
    path: "nanoAssetImages/:id",
    element: <TokenImages />,
  },
  {
    path: "WorkingCapital",
    element: <WCScreen api={"api/" + MANAGE_Investors_API} />,
  },
  {
    path: "investors",
    element: <Investors api={"api/" + MANAGE_Investors_API} />,
  },
  {
    path: "investments/:id",
    element: <Investments api={"api/" + MANAGE_Investors_API} />,
  },
  {
    path: "investmentDocuments/:id",
    element: <InvestmentDocuments />,
  },
  {
    path: "investmentsGraph/:id",
    element: <Chart />,
  },
  {
    path: "companyInvestments/:companyId",
    element: <CompanyInvestments api={MANAGE_ALLINVESTMENTS_API} />,
  },
  {
    path: "investorScreen",
    element: <InvestorScreen api={"api/" + MANAGE_Investors_API} />,
  },
  {
    path: "allInvestments",
    element: <AllInvestments api={"api/" + MANAGE_ALLINVESTMENTS_API} />,
  },
  { path: "nanoAssetInvestorScreen", element: <TokenInvestorScreen /> },
  {
    path: "nanoAssetInvestors",
    element: <TokenInvestors api={"api/" + MANAGE_TokenInvestors_API} />,
  },
  {
    path: "nanoAssetInvestorInvites",
    element: <InviteTokenInvestors api={MANAGE_TokenInvestors_API} />,
  },

  {
    path: "investorInvites",
    element: <InviteInvestors api={"api/" + MANAGE_Investors_API} />,
  },
  {
    path: "employeeScreen",
    element: <EmployeeScreen />,
  },
  {
    path: "AllEmployees",
    element: <Employees api={"api/" + MANAGE_Employees_API} />,
  },
  {
    path: "employeeInvites",
    element: <InviteEmployees api={EMPLOYEES_API} />,
  },
  {
    path: "customers",
    element: <InviteInvestors api={CUSTOMERS_API} />,
  },
  {
    path: "investorUsers",
    element: <InvestorUsers api={"api/" + MANAGE_INVESTOR_USERS_API} />,
  },
  {
    path: "investorUsersInvestors",
    element: <InvestorUsersInvestors api={"api/" + MANAGE_INVESTOR_USERS_INVESTORS_API} />,
  }, {
    path: "investorUsersInvestors/:id",
    element: <SingleInvestorUsersInvestors api={"api/" + MANAGE_INVESTOR_USERS_INVESTORS_API} />,
  },
  {
    path:"investors/investorUsers/:id",
    element:<SingleInvestorsInvestorUsers api={"api/" + MANAGE_INVESTOR_USERS_INVESTORS_API} />
  }

];

export default MainRoutes;
