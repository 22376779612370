import { useContext, useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Forms/Form";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import EmailInput from "../../../../Partials/Forms/FormInputs/EmailInput/EmailInput";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput"; // For Assign to Investor
import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_Investors_API } from "../../../../../Utilities/APIs/APIs";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";



function CreateInvestorUser({ api, setShowCreateForm, triggerFetch }) {
  const { error } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [assignedTo, setAssignedTo] = useState(""); // For Assign to Investor
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [toggleFetch, setToggleFetch] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { info: investorOptions } = useGetInfo({
    axiosInstance: axiosInstance,
    api: "api/" + MANAGE_Investors_API + "getAllInvestors",
    toggleFetch,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setHasError(false);
    let isValid = true;

    if (!name) {
      setNameError("Name is required.");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!isValid) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", name);
    formData.append("assignedTo", assignedTo); // Include the assigned investor
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await axiosInstance.post(api + "createInvestorUser", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        setEmail("");
        setName("");
        setAssignedTo("");
        setImage(null);
        setShowCreateForm(false);
        triggerFetch();
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label={"Name"}
        value={name}
        placeholder={"Please Enter a Name"}
        setState={setName}
        required
      />
      {nameError && <p className="error-text text-red-300">{nameError}</p>}

      <EmailInput
        label={"Email"}
        value={email}
        placeholder={"Please Enter an Email"}
        setState={setEmail}
        required
      />
      {emailError && <p className="error-text text-red-300">{emailError}</p>}

      <ImageInput
        allowCreateImage
        label={"Profile Image"}
        state={image}
        setState={setImage}
        fieldId={"Investor Image"}
      >
        Select Profile Picture
      </ImageInput>

      <SelectInput
        label={"Assigned To"}
        value={assignedTo}
        placeholder={"Please Enter Company Name"}
        setState={setAssignedTo}
      >
        <SelectOption optionValue={""} optionText={"Select Investor"} />
        {investorOptions?.length > 0 &&
          investorOptions?.map(({ _id, name }) => (
            <SelectOption key={_id} optionValue={_id} optionText={name} />
          ))}
      </SelectInput>

      {hasError && <p className="error-text text-red-300"> Failed to create investment user. Please try again.</p>}

      <FormSubmitButton text={isLoading ? "Loading..." : "Send Invite to Email"} disabled={isLoading} />
    </Form>
  );
}

export default CreateInvestorUser;
