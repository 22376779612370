import { Image } from "../../../Elements/Image/Image";

export function ImageCell({ imgSrc, imgAlt, assetOrWeb }) {
    return (
        <td>
            <Image
                imgLink={imgSrc}
                imgAlt={imgAlt}
                assetOrWeb
            />
        </td>
    )
}