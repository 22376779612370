import React, { useContext, useState, useMemo } from "react";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CRUDButton, EditButton } from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import CreateInvestor from "./CreateInvestor";
import { MANAGE_Investors_API } from "../../../../../../Utilities/APIs/APIs";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import UpdateInvestor from "./UpdateInvestor";
import { useNavigate } from "react-router-dom";

import "./wc_investor.css";
import { BsBriefcase, BsBriefcaseFill, BsFileEarmarkBarGraph } from "react-icons/bs";

const exportToCSV = (investors) => {
  const headers = ["Name", "Email Address", "Status"];
  const rows = investors.map(investor => [
    investor.name,
    investor.email,
    investor.isActive ? "Active" : "Inactive"
  ]);

  const csvContent =
    "data:text/csv;charset=utf-8," +
    [headers.join(","), ...rows.map(row => row.join(","))].join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "investors.csv");
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

function Investors({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const { info: investors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllInvestors",
    toggleFetch,
  });

  const navigate = useNavigate();

  const handleRowClick = (e, investor) => {
    const isButtonClick = e.target.closest(".wc_investor_action_buttons");
    if (!isButtonClick) {
      navigate("/main/investments/" + investor._id);
    }
  };

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const sortedInvestors = useMemo(() => {
    if (!investors) return [];
    return investors
      .slice()
      .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
  }, [investors]);

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`WC Investors (${investors?.length || 0} in total)`}</h1>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <CreateButton
            screenTopicSingular={"WC Investor"}
            setShowCreateForm={setShowCreateForm}
          />
          {/* <CRUDButton handleClick={() => exportToCSV(sortedInvestors)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>Export To CSV</p>
          </CRUDButton> */}
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth align={"left"} th="Name" />
          <CRUDth th="Phone" />
          <CRUDth th="Email Address" />
          <CRUDth th="Status" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {sortedInvestors &&
            sortedInvestors.map((investor) => (
              <tr
                key={investor._id}
                className="investor_link"
                onClick={(e) => handleRowClick(e, investor)}
              >
                <td>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <img
                      style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                      src={ investor.image ? process.env.REACT_APP_SPACES_URL + investor.image : `https://ui-avatars.com/api/?name=${investor?.name}&background=random&length=1` }
                      alt=""
                    />
                    <p>{investor.name}</p>
                  </div>
                </td>
                <ShortTextCell text={investor.phone} />
                <ShortTextCell text={investor.email} />
                <ShortTextCell
                  text={investor.isActive ? "Active" : "Inactive"}
                />
                <td className="action_buttons_wrapper">
                  <div className="action_buttons wc_investor_action_buttons">
                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={investor._id}
                      setTargetID={setTargetID}
                    />
                    <CRUDButton handleClick={() => navigate(`/main/investmentsGraph/${investor._id}`)}>
                      <BsFileEarmarkBarGraph size={25} />
                    </CRUDButton>
                    <CRUDButton handleClick={() => navigate(`/main/investors/investorUsers/${investor._id}`)}>
                      <BsBriefcaseFill size={25} />
                    </CRUDButton>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Investor`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Investor`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default Investors;
