import { useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../../../Partials/Forms/Form";
import NumberInput from "../../../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { MANAGE_Company_API } from "../../../../../../../Utilities/APIs/APIs";
import DateInput from "../../../../../../Partials/Forms/FormInputs/DateInput/DateInput";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SelectOption from "../../../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import SelectInput from "../../../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SwitchInput from "../../../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import ShortTextInput from "../../../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

export default function UpdateInvestment({
  api,
  targetID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  const { info: companies } = useGetInfo({
    axiosInstance: axiosInstance,
    api: "api/" + MANAGE_Company_API + "getActiveCompanies",
  });

  const [company, setCompany] = useState("");
  const [amount, setAmount] = useState(null);
  const [rate, setRate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [tenure, setTenure] = useState(null);
  const [disbursed, setDisbursed] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [daysToMaturity, setDaysToMaturity] = useState(null);

  // Fetch existing investment data
  useEffect(() => {
    async function fetchInvestmentData() {
      const response = await axiosInstance.get(
        api + "getSingleInvestment/" + targetID
      );
      if (response && response.data) {
        const investment = response.data;
        setCompany(investment.company);
        setAmount(investment.amount);
        setRate(investment.rate);
        setDate(new Date(investment.date));
        setTenure(investment.tenure);
        setDisbursed(investment.disbursed);
        setIsActive(investment.isActive);

        // Calculate days to maturity
        const maturityDate = new Date(investment.date);
        maturityDate.setDate(maturityDate.getDate() + investment.tenure);
        const today = new Date();
        const daysToMaturity = Math.ceil((maturityDate - today) / (1000 * 60 * 60 * 24));
        setDaysToMaturity(daysToMaturity);
      }
    }
    fetchInvestmentData();
  }, [api, targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = {
      company,
      amount,
      rate,
      date,
      tenure,
      disbursed,
      isActive,
    };
    const response = await axiosInstance.put(
      api + "updateSingleInvestment/" + targetID,
      itemData
    );

    if (response) {
      setCompany("");
      setAmount(0);
      setDate(new Date());
      setRate(0);
      setTenure(0);
      setDisbursed(null);
      setIsActive(null);
      setShowEditSection(false);
      triggerFetch();
    }
  }

  const handleDateChange = (newDate) => {
    setDate(newDate);
    // Recalculate days to maturity whenever the date changes
    const maturityDate = new Date(newDate);
    maturityDate.setDate(maturityDate.getDate() + tenure);
    const today = new Date();
    const days = Math.ceil((maturityDate - today) / (1000 * 60 * 60 * 24));
    setDaysToMaturity(days);
  };

  const handleTenureChange = (newTenure) => {
    setTenure(newTenure);
    // Recalculate days to maturity whenever the tenure changes
    const maturityDate = new Date(date);
    maturityDate.setDate(maturityDate.getDate() + newTenure);
    const today = new Date();
    const days = Math.ceil((maturityDate - today) / (1000 * 60 * 60 * 24));
    setDaysToMaturity(days);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <SelectInput
        label={"Company"}
        value={company}
        placeholder={"Please Enter Company Name"}
        setState={setCompany}
      >
        <SelectOption optionValue={""} optionText={"Select Company"} />
        {companies?.length > 0 &&
          companies?.map(({ _id, name }) => (
            <SelectOption key={_id} optionValue={_id} optionText={name} />
          ))}
      </SelectInput>
      <DateInput
        label={"Investment Date"}
        value={date}
        placeholder={"Please Enter Date"}
        setState={handleDateChange}
      />
      <NumberInput
        label={"Amount"}
        value={amount}
        placeholder={"Please Enter Amount (in tk)"}
        setState={setAmount}
      />
      <NumberInput
        label={"Annualized ROI (%)"}
        value={rate}
        placeholder={"Please Enter ROI"}
        setState={setRate}
      />
      <NumberInput
        label={"Tenure (Days)"}
        value={tenure}
        placeholder={"Please Enter Tenure (in days)"}
        setState={handleTenureChange}
      />
      <ShortTextInput
        label={"Days to Maturity"}
        value={daysToMaturity}
        placeholder={"Days to Maturity"}
        isDisabled={true}
      />
      <SwitchInput
        label={"Disbursed?"}
        value={disbursed}
        checked={disbursed}
        toggleSwitch={() => setDisbursed(!disbursed)}
        setState={setDisbursed}
      />
      <SwitchInput
        label={"IsActive?"}
        value={isActive}
        checked={isActive}
        toggleSwitch={() => setIsActive(!isActive)}
        setState={setIsActive}
      />

      <FormSubmitButton text="Update Investment" />
    </Form>
  );
}
