import { useState, useEffect } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Forms/Form";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput"; // For Assign to Investor
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { MANAGE_INVESTOR_USERS_INVESTORS_API, MANAGE_Investors_API } from "../../../../../Utilities/APIs/APIs";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function UpdateInvestorUser({ api, setShowUpdateForm, triggerFetch, targetID }) {
    const [assignedTo, setAssignedTo] = useState(""); // For Assign to Investor
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const axiosInstance = useAxiosInstance();
    const [toggleFetch, setToggleFetch] = useState(false);

    // Fetch the list of investors
    const { info: investorOptions } = useGetInfo({
        axiosInstance: axiosInstance,
        api: "api/" + MANAGE_Investors_API + "getAllInvestors",
        toggleFetch,
    });

    useEffect(() => {
        async function fetchUserData() {
            setIsFetching(true);
            const response = await axiosInstance.get(api + "getSingleInvestorUser/" + targetID);
            const { assignedTo } = response.data;
            setAssignedTo(assignedTo); // Pre-fill the form with the assigned investor
            setIsFetching(false);
        }

        fetchUserData();
    }, [axiosInstance, api, targetID]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();
        formData.append("investor", assignedTo); // Assigned investor ID
        formData.append("investorUser", targetID); // Target investor user ID

        await axiosInstance.post(
            MANAGE_INVESTOR_USERS_INVESTORS_API + "addInvestorToInvestorUser/",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        setAssignedTo("");
        setShowUpdateForm(false);
        triggerFetch(); // Fetch updated data
        setIsLoading(false);
    };

    if (isFetching) {
        return <div>Loading...</div>;
    }

    return (
        <Form onSubmit={handleSubmit}>
            {/* Select input for assigning the user to an investor */}
            <SelectInput
                label={"Assign To"}
                value={assignedTo}
                placeholder={"Select an Investor"}
                setState={setAssignedTo}
                required
            >
                <SelectOption optionValue={""} optionText={"Select Investor"} />
                {investorOptions?.length > 0 &&
                    investorOptions.map(({ _id, name }) => (
                        <SelectOption key={_id} optionValue={_id} optionText={name} />
                    ))}
            </SelectInput>

            <FormSubmitButton
                text={isLoading ? "Updating..." : "Update Investment User"}
                disabled={isLoading}
            />
        </Form>
    );
}

export default UpdateInvestorUser;
