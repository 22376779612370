// function takaFormatter(num) {
//     let n1, n2;
//     num = num + "" || "";
//     n1 = num.split(".");
//     n2 = n1[1] || null;
//     n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
//     num = n2 ? n1 + "." + n2 : n1;
//     let number = "৳ " + num;
//     return number;
// }

// export default TakaFormatter;

export function numberFormatter(num) {
  return Intl.NumberFormat("en-IN").format(num);
}

export function takaFormatter(num) {
  return "৳ " + numberFormatter(Math.round(num ));
}
