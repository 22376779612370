import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_Company_API } from "../../../../../Utilities/APIs/APIs";
import { useEffect, useState } from "react";
import SelectInput from "../../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import Form from "../../../../Partials/Forms/Form";
import SelectOption from "../../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import DateInput from "../../../../Partials/Forms/FormInputs/DateInput/DateInput";
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import SwitchInput from "../../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

export default function UpdateToken({
  api,
  targetID,
  setShowEditSection,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  // Fetch companies for the select input
  const { info: companies } = useGetInfo({
    axiosInstance,
    api: `api/${MANAGE_Company_API}getActiveCompanies`,
  });

  const [company, setCompany] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [thumbnail, setThumbnail] = useState("demo.png");
  const [tokenDescription, setTokenDescription] = useState("");
  const [totalIssuedTokens, setTotalIssuedTokens] = useState(0);
  const [lockInPeriod, setLockInPeriod] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [finalInvestmentDate, setFinalInvestmentDate] = useState(new Date());
  const [initialTokenValue, setInitialTokenValue] = useState(0);
  const [roi, setRoi] = useState(0);

  useEffect(() => {
    async function fetchTokenData() {
      try {
        const response = await axiosInstance.get(
          `${api}getSingleToken/${targetID}`
        );

        if (response && response?.data?.data) {
          const token = response?.data?.data;
          setCompany(token.company._id || "");
          setTokenName(token.tokenName || "");
          setThumbnail(token.thumbnail || "");
          setTokenDescription(token.tokenDescription || "");
          setTotalIssuedTokens(token.totalIssuedTokens || 0);
          setLockInPeriod(token.lockInPeriod || 0);
          setTenure(token.tenure || 0);
          setFinalInvestmentDate(new Date(token.finalInvestmentDate) || new Date());
          setInitialTokenValue(token.initialTokenValue || 0);
          setRoi(token.roi || 0);
        }
      } catch (error) {
        console.error("Error fetching token data", error);
      }
    }
    fetchTokenData();
  }, [api, targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const tokenData = {
      company,
      tokenName,
      thumbnail,
      tokenDescription,
      totalIssuedTokens,
      lockInPeriod,
      tenure,
      finalInvestmentDate,
      initialTokenValue,
      roi,
    };

    try {
      const response = await axiosInstance.put(
        `${api}updateSingleToken/${targetID}`,
        tokenData
      );
      if (response) {
        resetForm();
        setShowEditSection(false);
        triggerFetch(); // Refresh the token list after updating
      }
    } catch (error) {
      console.error("Error updating token", error);
    }
  }

  // Reset the form after submission
  function resetForm() {
    setCompany("");
    setTokenName("");
    setThumbnail("demo.png");
    setTokenDescription("");
    setTotalIssuedTokens(0);
    setLockInPeriod(0);
    setTenure(0);
    setFinalInvestmentDate(new Date());
    setInitialTokenValue(0);
    setRoi(0);
  }


  return (
    <Form onSubmit={handleSubmit}>
      <SelectInput
        label={"Company"}
        value={company}
        placeholder={"Please select a company"}
        setState={setCompany}
      >
        <SelectOption optionValue={""} optionText={"Select Company"} />
        {companies?.length > 0 &&
          companies?.map(({ _id, name }) => (
            <SelectOption key={_id} optionValue={_id} optionText={name} />
          ))}
      </SelectInput>
      <ShortTextInput
        label={"Token Name"}
        value={tokenName}
        placeholder={"Enter Token Name"}
        setState={setTokenName}
      />

      <ShortTextInput
        label={"Token Description"}
        value={tokenDescription}
        placeholder={"Enter Token Description"}
        setState={setTokenDescription}
      />
      <NumberInput
        label={"Total Issued Tokens"}
        value={totalIssuedTokens}
        placeholder={"Enter Total Issued Tokens"}
        setState={setTotalIssuedTokens}
      />
      <NumberInput
        label={"Lock-in Period (Days)"}
        value={lockInPeriod}
        placeholder={"Enter Lock-in Period in Days"}
        setState={setLockInPeriod}
      />
      <NumberInput
        label={"Tenure (Days)"}
        value={tenure}
        placeholder={"Enter Tenure in Days"}
        setState={setTenure}
      />
      <DateInput
        label={"Final Investment Date"}
        value={finalInvestmentDate}
        placeholder={"Enter Final Investment Date"}
        setState={setFinalInvestmentDate}
      />
      <NumberInput
        label={"Initial Token Value"}
        value={initialTokenValue}
        placeholder={"Enter Initial Token Value"}
        setState={setInitialTokenValue}
      />
      <NumberInput
        label={"Annualized ROI (%)"}
        value={roi}
        placeholder={"Enter ROI"}
        setState={setRoi}
      />

      <NumberInput
        label={"Tenure (Days)"}
        value={tenure}
        placeholder={"Please enter tenure"}
        setState={setTenure}
      />


      <FormSubmitButton text="Update Token" />
    </Form>
  );
}
