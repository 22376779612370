import { useState } from 'react'
import ImageUploadThumb from './image-thumb.png'
import FileUploadThumb from './file-thumb.png'

import './ImageInput.css'

function ImageInput({ fieldId, state, setState, allowCreateImage, allowUpdateImage, children, isFile }) {
  const [preview, setPreview] = useState('')
  const [filename, setFilename] = useState('')

  const handleChange = (e) => {
    const file = e.target.files[0]
    setState(file)
    setFilename(file.name)


    if (file && file.type.startsWith('image/')) {
      setPreview(URL.createObjectURL(file))
    } else {
      setPreview('')
    }
  }

  const defaultThumb = isFile ? FileUploadThumb : ImageUploadThumb

  return (
    <>
      <label className='upload_file_wrapper ' htmlFor={`fileInput${fieldId}`}>
        <span className='input_field_label '>{children}</span>
        <div className="preview_image !text-white">
          {state ? (

            preview ? (
              <img
                src={preview}
                alt="uploaded preview"
              />
            ) : (
              <span className='file_name'>{filename}</span>
            )
          ) : (

            <img
              src={defaultThumb}
              alt="default thumb"
            />
          )}
        </div>
      </label>

      <input
        id={`fileInput${fieldId}`}
        type='file'
        className='file_upload_input'
        onChange={handleChange}
      />
    </>
  )
}

export default ImageInput
 