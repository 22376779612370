import { useContext, useState } from "react";
import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation.js";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance.js";
import { LayoutContext } from "../../../../Contexts/GlobalContexts/LayoutContext.js";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { CreateButton } from "../../../Partials/Layout/CRUD/CreateButton/CreateButton.js";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable.js";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader.js";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth.js";
import {
  CRUDButton,
  DeleteButton,
  EditButton,
  ViewButton,
} from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons.js";
import { Modal } from "../../../Partials/Elements/Modal/Modal.js";
import { IconThreeDots } from "antopolis-react-icons";
import ViewCompany from "./ViewCompany.js";
import CreateCompany from "./CreateCompany.js";
import UpdateCompany from "./UpdateCompany.js";
import { ShortTextCell } from "../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell.js";
import { useNavigate } from "react-router-dom";
import { FaBriefcase } from "react-icons/fa";
import BackButton from "../../../Partials/Elements/BackButton/BackButton.js";
import DeleteCompany from "./DeleteCompany.js";

// Function to export data as CSV
const exportToCSV = (companies) => {
  const headers = ["Name", "Status"];
  const rows = companies.map(company => [
    company.name,
    company.isActive ? "Active" : "Inactive"
  ]);

  const csvContent =
    "data:text/csv;charset=utf-8," +
    [headers.join(","), ...rows.map(row => row.join(","))].join("\n");

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "companies.csv");
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

function Companies({ api }) {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
  const { toggleFetch } = useContext(LayoutContext);

  const navigate = useNavigate();

  const { info: companies } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllCompanies",
    toggleFetch,
  });


  const sortedCompanies = companies?.sort((a, b) => b.isActive - a.isActive);

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">Companies ({sortedCompanies?.length} in total)</h1>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <CreateButton
            screenTopicSingular={"Company"}
            setShowCreateForm={setShowCreateForm}
          />

          {/* <CRUDButton handleClick={() => exportToCSV(companies)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>Export To CSV</p>
          </CRUDButton> */}


        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>

          <CRUDth align={"left"} th="Name" />
          <CRUDth align={"right"} th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {sortedCompanies?.map((company) => (
            <tr key={company._id}>
              <td className="action_buttons_wrapper">
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <span
                    style={{
                      backgroundColor: company.isActive ? "green" : "red",
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      margin: "auto 5px",
                    }}
                    className="!-ms-8"
                  />
                  <p>{company.name}</p>
                </div>
              </td>
              {/* <ShortTextCell text={company.isActive ? "Active" : "Inactive"} /> */}
              <td>
                <div
                  style={{ textAlign: "center" }}
                  className="action_buttons_wrapper"
                >
                  <button
                    className="three_dots"
                    onClick={() => {
                      if (targetID === company._id) {
                        setTargetID("");
                      } else {
                        setShowActionButton(true);
                        setTargetID(company._id);
                      }
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div
                      style={{ justifyContent: "end" }}
                      className="action_buttons"
                    >
                      {/* <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={company._id}
                        setTargetID={setTargetID}
                      /> */}
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={company._id}
                        setTargetID={setTargetID}
                      />
                      <CRUDButton handleClick={() => navigate(`/main/companyInvestments/${company._id}`)}>
                        <FaBriefcase size={25} />
                      </CRUDButton>
                      <DeleteButton setTargetID={setTargetID} setShowDeleteSection={setShowDeleteSection} targetID={company._id} />
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === company._id && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={company._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={company._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Company`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateCompany
            setShowCreateForm={setShowCreateForm}
            api={"employeeApp/protected/manageCompanies/"}
            targetID={targetID}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Company`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateCompany
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            api={`${api}company/`}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Company`}
          setShowModalContent={setShowViewSection}
        >
          <ViewCompany targetID={targetID} api={`${api}/company/`} />
        </Modal>
      )}

      {
        showDeleteSection &&
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Company`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteCompany
            setShowDeleteSection={setShowDeleteSection}
            targetID={targetID}
            api={`${api}company/`}
          />
        </Modal>
      }
    </div>
  );
}

export default Companies;
