import { useState } from "react";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";

function TokenInvestors({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();
  const { info: tokenInvestors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllTokenInvestors",
    toggleFetch,
  });
  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Nano Asset Investors (${tokenInvestors?.length} in total)`}</h1>
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Image" />
          <CRUDth th="Name" />
          <CRUDth th="Email Address" />
          {/* <CRUDth th="Level" /> */}
          {/* <CRUDth th="Actions" /> */}
        </CRUDTableHeader>
        <tbody>
          {tokenInvestors &&
            tokenInvestors?.map((e) => (
              <tr key={e._id}>
                <ImageCell imgSrc={e.dp} imgAlt={e.name} />
                <ShortTextCell text={e.name} />
                <ShortTextCell text={e.email} />
                {/* <ShortTextCell text={e.level} /> */}
              </tr>
            ))}
        </tbody>
      </CRUDTable>
    </div>
  );
}

export default TokenInvestors;
