import React from "react";

import { RiGroupLine } from "react-icons/ri";
import { ScreenHolder } from "../../../Partials/Layout/ScreenHolder/ScreenHolder";
import { NavCardList } from "../../../Partials/Layout/NavCardList/NavCardList";
import { NavCard } from "../../../Partials/Layout/NavCardList/NavCard/NavCard";
import { GiToken } from "react-icons/gi";

function TokenScreen() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={"two"}>
        <NavCard
          cardName={"Nano Asset Investors"}
          navCardLink="/main/nanoAssetInvestorScreen"
        >
          <RiGroupLine />
        </NavCard>
        <NavCard
          cardName={"Nano Assets"}
          navCardLink="/main/NanoAssets"
        >
          <GiToken />
        </NavCard>
      </NavCardList>
    </ScreenHolder>
  );
}

export default TokenScreen;
