import { useContext, useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { LayoutContext } from "../../../../Contexts/GlobalContexts/LayoutContext";
import { useGetInfo } from "antopolis-react-utils/hooks";

function DeleteCompany({
    api,
    targetID,
    setShowDeleteSection,
    setShowModal,

}) {
    const axiosInstance = useAxiosInstance();

    const { triggerFetch } = useContext(LayoutContext);

    const { info: company } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + targetID,
    });


    console.log(company)

    console.log("Entered delete company");

    async function handleDelete() {
        const response = await axiosInstance.delete(
            "employeeApp/protected/manageCompanies/company/" + targetID,
        );

        if (response) {
            setShowDeleteSection(false);
            triggerFetch();
        }
    }
    return (
        <div className="modal_delete_content">
            <p className="text-gray-200">Are you sure you want to delete {company?.name}?</p>

            <div className="modal_group_buttons">
                <button
                    className="delete"
                    onClick={() => {
                        handleDelete();
                    }}
                >
                    Delete
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowModal(false);
                        setShowDeleteSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default DeleteCompany;
